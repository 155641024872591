.tri__card__container{
    padding-top: 50px;
    padding-bottom: 50px;
}

/* CARDS CONTAINER */
.card__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

/* ==== MEDIA QUERIES (SMALL DEVICES) ==== */
@media only screen and (max-width: 1024px) and (min-width: 601px) {
    .card__container {
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
    }
    .cardWithTitleImageDescButton__container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
}

@media screen and (max-width: 600px) {
    .card__container {
      grid-template-columns: 1fr;
      gap: 4rem;
    }
    .cardWithTitleImageDescButton__container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
}