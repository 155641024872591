.Loader-div > div {
    height: initial !important;
    width: initial !important;
}

.search-wrapper{
    margin-top: 20px;
}

.user-search-term-num,
.user-search-term{
    color: #A2BB0C;
}

.search-summary-default{
    margin-bottom: 20px;
}