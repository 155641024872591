.imagetextleftright__container h2 {
  font-size: 30px;
  margin: 10px 0;
  line-height: 40px;
  font-weight: 700;
  font-family: "ManropeBold";
}
.imagetextleftright__container p {
  line-height: 30px;
  font-size: 18px;
  color: #636362;
}
.year {
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 20px;
}
.style__img {
  width: 100%;
  height: auto;
}
.close__button {
  display: block;
  margin: 0;
  text-align: right;
}
.close__button button {
  margin: 0;
}
.modal__button {
  background-color: white;
  font-size: 20px;
}
.text_description_center {
  font-size: 18px;
  text-align: left;
  line-height: 30px;
  overflow-y: auto;
  max-height: 50vh;
}
.m80 {
  margin-bottom: 80px;
}
@media (max-width: 1300px) {
  .mb__10 {
    margin-bottom: 10%;
  }
  .m80 {
    margin-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .reverse {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 767px) {
  .mb__5 {
    margin-bottom: 4%;
  }
}
