/* top content */
.has-rep {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    width: calc(100% - 5%);
    -webkit-user-select: none;
    user-select: none;
}

.new {
    width: calc(100% - 5%) !important;
}

.has-select p {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 28px;

}

/* end */


/* 搜索 */
.search-read-more{
    background: #a2bb0c;
    color: #fff;
    padding: 8px 15px;
    border-radius: 4px;
    font-family: 'ManropeBold';
    font-size: 14px;
    white-space: normal;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    line-height: 30px;
}
.search-read-more:hover{
    color: #fff;
    background: #007F8F;
}

input::-webkit-input-placeholder {
    color: #000 !important;
}

.form-search {
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 0);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 0);
    padding-right: 55px;
    font-size: 18px !important;
    font-weight: bold !important;
    color: #000;
}

.form-search:focus {
    border-color: #a2bb0c !important;
    outline: 0 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 0%), 0 0 8px rgb(162 187 12 / 50%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 0%), 0 0 8px rgb(162 187 12 / 50%) !important;
}

.header-icon-search {
    cursor: pointer;
}

.search-modal {
    background: rgba(0, 0, 0, 0.4);
    display: none;
    opacity: 0;
    position: fixed;
    bottom: 0;
    left: -9999rem;
    top: 0;
    transition: opacity 0.2s linear, left 0s 0.2s linear;
    width: 100%;
    height: 100vh;
    z-index: 900;
}

.cover-modal {
    display: none;
}

.cover-modal.show-modal {
    display: block;
    cursor: pointer;
}

.search-modal.active {
    left: 0;
    opacity: 1;
    transition: opacity 0.2s linear;
}

.b-search {
    position: absolute;
    z-index: 1000;
    width: 94%;
    margin: 0 auto;
    /* margin-top: 27px; */
    height: 54px;
    /* padding-left: 15px; */
    padding-right: 35px;
    background: #fff;


}

.search-blcok {
    width: 100%;
    height: 100px;
    background: #fff;
    position: fixed;
    /* position: absolute; */
    z-index: 100;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 1px 7px 0 rgb(23 26 33 / 10%);
    transform: translateY(-100%);
    transition: transform 0.15s linear, box-shadow 0.15s linear;
    z-index: 999;
    align-items: center;
}

.search-blcok.active-hight {
    height: 150px;
}

.search-blcok.active {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out, box-shadow 0.1s 0.25s linear;
}

.b-search a.btn-b-search {
    position: absolute;
    right: 60px;
    z-index: 4;
    height: inherit;
    line-height: 45px;
    padding-left: 20px;
    bottom: 1px;
    cursor: pointer;
}

.b-search button.b-close {
    position: absolute;
    right: 0px;
    z-index: 4;
    height: inherit;
    bottom: 4px;
    background: #fff;
}


.choose-bg {
    background-color: #f6f6f6;
    padding: 5px;
}

.choose-content {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choose-content .common-btn {
    background-color: #A2BB0C;
    height: auto;
    line-height: normal;
    padding: 10px 16px;
    color: #fff;
    font-weight: bold;
}

.choose-content .choose-txt {
    font-size: 14px;
}

.has-select {
    height: 32px;

    position: relative;
    cursor: pointer;
    max-width: 400px;
    width: 100%;
}

.has-select p {
    display: block;
    padding-left: 40px;
    line-height: 31px;
    border: 1px solid #CCC;
    border-radius: 5px;
    font-size: 16px;

}

.has-rep {
    position: relative;
}

.Angle-icon {
    position: absolute;
    right: 14px;
    top: 4px;
}

ul {
    padding: 0px;
}

.new {
    width: 100%;
    position: absolute;
    border: 1px solid #CCC;
    display: none;
    z-index: 1100000001;
    background-color: #fff;
    top: 30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.has-select ul li {
    line-height: 30px;
    padding-left: 40px;
    font-size: 16px;
    background-color: #f6f6f6;
    border-bottom: 1px solid #bfbfbf;
    position: relative;
}

.has-select ul li:last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.has-select ul li:hover {
    background: #CCC;
    color: #FFF;
}

.Angle-up {
    display: none;
}

.has-select li .Angle-up {
    display: none;
}

.has-select li .Angle-down {
    display: block;
}

.has-select .active .Angle-up {
    display: block;
}

.has-select .active .Angle-down {
    display: none;
}


.right-select {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-around;
}

/* footer */
.footer_social .footer_icon:last-child {
    margin-right: 0;
}

.footer-lag {
    font-size: 14px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.menu .footer-lag span {
    padding: 0px;
    margin: 0px;
    margin: 0 5px;
}

.footer-lag .footer-line {
    padding: 0 10px;
}

@media (max-width:1600px) {
    .search-blcok {
        width: 100%;
        height: 100px;
        background: #fff;
    }

    .b-search {
        width: 95%;
    }
}

@media (max-width: 1480px) {
    .search-blcok {
        width: 100%;
        height: 129px;
        background: #fff;
        top: 0px;
        padding-right: 0;
        justify-content: space-evenly;
    }

    .b-search {
        width: 90%;
        margin: 0 auto;
        height: 54px;
        top: 42px;
    }

}

@media (max-width: 1179px) {
    .search-blcok {
        height: 139px;
    }
}


@media (max-width: 1136px) {

    .top-form-block {
        width: 100%;
        margin-left: 0%;
        margin-top: -7% !important;
    }

    .temp-block-a {
        padding-top: 42%;
    }

    .top-content-form {
        position: absolute;
        top: 110%;
        right: 0%;
        max-width: 100%;
        width: 100%;
        height: auto;

    }

}

@media (max-width: 999px) {
    .footer-r {
        text-align: center;
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .top-content-form {
        top: 100%;
    }

    .temp-block-a {
        padding-top: 52%;
    }

}

@media (max-width: 872px) {
    .footer-r {
        text-align: center;
        margin-top: 15px;
    }

    .footer-lag {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer-lag .footer-line {
        width: 5%;
    }
}


@media (max-width: 768px) {

    /* Ã¦ÂÅ“Ã§Â´Â¢ */

    .search-blcok {
        height: 100px;
    }

    .b-search {
        width: 90%;
        margin: 0 auto;
        height: 54px;
        top: 30px;
    }

    .top-content-form {

        top: 96%;

    }

    .temp-block-a {
        padding-top: 60%;
    }

    .form-search {

        padding-right: 53px;
    }
}

@media (min-width: 550px) and (max-width: 678px) {
    .temp-block-a {
        padding-top: 65%;
    }
}

@media (max-width: 549px) {
    .temp-block-a {
        padding-top: 90%;
    }
}

@media (max-width: 430px) {
    .temp-block-a {
        padding-top: 120%;
    }
}

/* content */


.search-text {
    color: #000 !important;
    margin-bottom: 90px;
}

.search-mod {
    margin-bottom: 140px;
}

.search-blcok-box {
    margin-bottom: 90px;
}

.search-blcok-box .b-search-box {
    position: relative;
}

.b-search-box .form-search {
    padding-right: 61px;
    font-size: 18px;
    color: #000;
    font-weight: bold;
    padding-left: 25px;
}

.search-blcok-box .b-search-box a.btn-b-search {
    position: absolute;
    right: 25px;
    z-index: 4;
    height: inherit;
    line-height: 45px;
    padding-left: 20px;
    bottom: 1px;
    cursor: pointer;
}

.search-title {
    padding: 0;
    padding-bottom: 45px;
    color: #000;
    line-height: 40px;
}

.search-content {
    background-color: #f6f6f6;
    margin: 0;
    padding: 45px;
    border-radius: 10px;
    margin-bottom: 60px;
}

.search-content .active {
    color: #a2bb0c;
    font-weight: bold;
}

.search-content .cont-txt {
    margin: 0px;
    margin-top: 45px;

}

.ls-dropdown-check::before {
    content: '';
    background: url('../images/NL_icons/Check.png') no-repeat bottom right;
    position: absolute;
    z-index: 2;
    width: 20px;
    height: 20px;
    left: 13px;
    top: 5px;
}

@media (max-width: 1500px) {
    .search-mod {
        padding: 30px;
    }
}

@media (max-width: 1182px) {
    .header-nav.header-height {
        height: 240px;
    }

    .choose-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        max-width: 100%;
        margin: 0;
        padding: 0px 20px;
    }

    .btn-close-top {
        position: absolute;
        right: 12px;
        top: 6px;
    }

    .right-select {
        width: 100%;
        padding-top: 13px;
        align-items: center;
        justify-content: flex-start;

    }

    /* .has-select{
    margin-right: 16px;
} */
    .choose-content .choose-txt {
        padding: 0px;
        padding-right: 20px;
        line-height: 20px;
    }

    .choose-bg {
        padding: 13px;
    }

}


@media (max-width:768px) {
    .search-text {
        color: #000;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .search-title {
        margin: 0px;
        padding-bottom: 35px;
    }

    .search-blcok-box {
        margin-bottom: 40px;
    }

    .search-content {
        padding: 35px;
    }

    .search-content .cont-txt {
        margin-top: 35px;
    }

    .b-search-box .form-search {
        padding-right: 53px;
    }

    .search-mod {
        margin: 0;
        padding-bottom: 30px;
    }

    .choose-content {
        padding: 0px;
    }

    .nav-drill.active {
        top: unset;
        margin: 0;
    }

    .search-blcok.active-hight {
        height: 111px;
    }
}

@media (max-width:600px) {
    .nav-drill.active {
        top: unset;
        margin: 0;
    }
}

@media (max-width:420px) {
    .ls-dropdown-check::before {
        content: '';
        background: url('../images/NL_icons/Check.png') no-repeat bottom right;
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        left: 5px;
        top: 5px;
    }

    .has-select p {
        padding-left: 28px;

    }

    .has-select ul li {
        padding-left: 28px;

    }

    .has-select p {
        padding-left: 26px;
        font-size: 14px;
    }

    .right-select {
        flex-wrap: wrap !important;
        width: 100%;
    }

    .common-box {
        margin-top: 10px;
    }

    .has-select ul li,
    .has-select p {
        font-size: 14px;
        line-height: 33px !important;
    }

    .Angle-icon {
        right: 5px;
        top: 6px;
    }

    .has-select {
        margin-right: initial;
        margin-bottom: 10px;
    }

    .has-select ul li {
        padding-left: 26px;
    }

    .ls-dropdown-check::before {
        content: '';
        top: 8px;
    }


}



/* 20231226 footer---------------------- */
.footer-links ul{
    display: flex !important;
    justify-content: flex-end;
}

#menu-footer-menu-corporate .menu-item:first-child{
    width: 25%;
}
/* #menu-footer-menu-corporate .menu-item:nth-child(2){
    width: 4%;
} */

#menu-footer-menu-corporate .menu-item:last-child{
    /* width: 66%; */
    display: flex;
    justify-content: flex-end;
}

/* .footer-lag{
    display: flex;
} */

.menu .footer-lag span{
    line-height: 24px;  
    padding: 0px;
    margin: 0px;
   padding: 0 5px;  
  
}

.privacy-content{
    margin-right: 10% !important;
}

.footer-links ul li a{
    line-height: 18px;
}
.footer-links ul li{
    padding: 0px 5px;
}

.menu .footer-lag span{
    text-align: center;
}


@media (max-width: 1200px) {
    .footer-r{
        margin-top: 20px;
        width: 100%;
    }

    .footer-links ul{
        flex-direction: column;
    align-items: center;
    }

    #menu-footer-menu-corporate .menu-item:first-child{
        width: 100%;
        margin: 0 auto;
    }
    #menu-footer-menu-corporate .menu-item:nth-child(2){
        width: 100%;
        margin: 0 auto;
    }
    #menu-footer-menu-corporate .menu-item:last-child{
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }
    .footer-lag{
        /* display: flex; */
        justify-content: center;
        align-items: flex-start;
    }
    .privacy-content{
        margin-right: 0px !important;
    }

    #menu-footer-menu-corporate .menu-item{
       padding-bottom: 10px;
    }
    .footer_social{
        text-align: center;
    }

}

/* 20231226 footer----------------------end */