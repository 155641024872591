.banner__container {
  position: relative;
}
.banner__img {
  display: block;
  width: 100%;
  height: auto;
}
.title__text {
  position: absolute;
  bottom: 37%;
  left: 13%;
}
.title__text h2 {
  font-size: 88px;
  color: #fff;
  font-weight: bold;
  line-height: 100px;
  font-family: "ManropeBold";
}
.arrow__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.arrowTransform {
  position: absolute;
  left: 50%;
  bottom: 23%;
  cursor: pointer;
}
.arrow__button span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  -webkit-animation: arrow 2s infinite;
  animation: animate 2s infinite;
}
.arrow__button span:nth-child(2) {
  animation-delay: -0.2s;
  -webkit-animation-delay: -0.2s;
}
.arrow__button span:nth-child(3) {
  animation-delay: -0.4s;
  -webkit-animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.svg__block__1__up svg {
  fill: #ffff;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 1400px) and (min-width: 992px) {
  .arrowTransform {
    bottom: 30%;
  }
}
@media (max-width: 570px) {
  /* .title__text h2 {
    font-size: 30px;
    line-height: 40px;
  } */
  .title__text h2 {
    font-size: 18px;
    line-height: 20px;
  }
  .title__text{
      left: 10%;
      /* top: 50%; */
  }
}
/* css for different screen size */
@media (min-width: 571px) and (max-width: 992px) {
  .title__text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .title__text{
    left: 13%;
    /* top: 45%; */
  }
  /* .banner__img{
    padding-top: 70px;
  } */
}
@media (min-width: 1118px) and (max-width: 1600px) {
  .title__text h2 {
    font-size: 55px;
    line-height: 70px;
  }

}
@media (max-width: 1117px) and (min-width: 769px) {
  .title__text h2 {
    font-size: 40px;
    line-height: 50px;
  }
   /* .banner__img{
    padding-top: 70px;
} */
}
@media (max-width: 992px) {
  .arrowTransform {
    display: none;
  }
}
@media (max-width: 1400px) and (min-width: 992px) {
  .arrow__button span {
    width: 28px;
    height: 28px;
  }
}
