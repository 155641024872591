.temp-white-block {
    max-width: 1440px;
    width: 100%;
    padding: 0 25px;
    margin: 0 auto;
}

.content-media--video {
    background-color: #ddd;
    display: block;
    padding: 0 0 0 0;
}

.content-media--img {
    background-color: #ddd;
    display: block;
    padding: 0 0 56.25% 0;
}

.content-media--video iframe {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#featured-video {
    transition: width .2s ease-in-out, height .2s ease-in-out, transform .38s ease-in-out;
}

.video-body {
    padding: 0 25px;
    padding-bottom: 25px;
}

.video-box {
    width: 100%;
}
.m80 {
    margin-bottom: 80px !important;
    margin-top: 80px !important;
  }
.m55 {
    margin-bottom: 55px !important;
    margin-top: 80px !important;
  }
@media (max-width: 768px) {
    .video-body {
        padding: 0 0;
    }
}

.custom-youtube-player {
    width: 100%;
}

.top-card-b {
    margin: 15px 0;
}

.img-responsive {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 可选，用于控制图片的缩放方式 */
}


.content-media-C17video {
    background-color: #ddd;
    display: block;
    position: relative;
    padding: 0 0 56.25% 0;
}

.C17video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-title-left {
    text-align: left;
    width: 95%;
}

.video-title-right {
    text-align: right;
    width: 95%;
}

.video-title-center {
    text-align: center;
    width: 95%;
}

.card-image {
    position: relative;
}

.cursor {
    cursor: pointer;
}

.video-icon-box {
    position: absolute;
    background: #A2BB0C;
    z-index: 1;
    width: 60px;
    height: 60px;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    border-radius: 50px;
    display: table-cell;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.plyr__video-embed {
    aspect-ratio: unset !important;
}