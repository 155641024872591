.top_card_b {
  margin: 15px 0;
}
.card_margin {
  margin: 50px auto 80px;
}
.close {
  color: #000;
  filter: alpha(opacity=20);
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  opacity: .2;
  text-shadow: 0 1px 0 #fff;
}

.close:focus,
.close:hover {
  color: #000;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
  text-decoration: none;
}

.card_top_block .btn_b {
  border-radius: 50px;
  background: #fff;
  color: #A2BB0C;
  font-weight: bold;
}

.card_top_block .btn_b:hover {
  background-color: #a2bb0c;
  color: #fff;
}

.card_top_block {
  margin: 20px;
}

.txt_block {
  bottom: 20px;
  color: #fff;
  left: 0;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.txt_block h3 {
  font-size: 30px;
  font-family: 'ManropeBold';
  margin-bottom: 16px;
  color: #fff;
}

.txt_block>p {
  line-height: 28px;
  max-height: 108px;
  overflow: hidden;
}

.card_caption {
  z-index: 1;
  width: 100%;
  height: 100%;
  flex-flow: column;
  display: flex;
  position: absolute;
  top: 0;
}

.card_image {
  position: relative;
  /* overflow: hidden; */
}

.card_block .card_image img {
  width: 100%;
  height: auto;
}

.card_image img {
  border-radius: 8px;
}

.about-title {
  bottom: 46%;
}

.history_content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;

}


.pop_layer {
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.4);
  /* display: none; */
  /* border: 1px solid red; */
}

.modal_dialog_centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-header .close {
  margin-top: -60px;
  margin-right: -15px;
}

.team_member_social {
  text-align: center;
}

.icon {
  font-size: 40px;
}

.modal-header {
  padding: 0;
  min-height: 0px;
}

.card_image .name-text {
  width: 100%;
  text-align: center;
  margin: 0px;
}

.tab_content {}

.tab_content .row {
  padding-bottom: 20px;
}

.modal-title {
  font-size: 30px;
  text-align: center;
  font-family: 'ManropeBold';
}

.txt-block h3 {
  font-size: 30px;
  font-family: 'ManropeBold';
  margin-bottom: 16px;
  color: #fff;
}

.card_top_block .btn-b:hover {

  background-color: #a2bb0c;
  color: #fff;
}

.card_block {
  cursor: pointer;
  background: #f5f5f5;
  border-radius: 8px;
  padding-top: 0;
  transition: transform .3s cubic-bezier(.38, .41, .27, 1);
  overflow: hidden;
}

.card_block:hover {
  box-shadow: initial;
}

.modal-dialog {
  margin: 10px;
  position: relative;
  width: auto;
}

.modal_content {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  outline: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal_dialog .modal_content {
  padding: 20px;
}

.modal_content .modal-header {
  border: none;
}

.modal_content .modal_body {
  line-height: 30px;
}

.modal_body {
  padding: 15px;
  position: relative;
}

@media (min-width: 2700px) {
  .top-line {
    display: none;
  }
}

@media (max-width: 1200px) {
  .tab_content .row {
    padding-bottom: 0px;
  }
}

.ab-title-block {
  padding-left: 0px;
}

@media (max-width: 1080px) {

  .history_content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.card {
  border: none !important;
}

.common_content_w .ab-title {
  padding-left: 40px;
}

.row {
  --bs-gutter-x: 0rem;
}

button.close {
  text-align: right;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

@media (min-width: 1440px) {
  .common_content_w {
    max-width: 1440px;
    width: 100%;
    padding: 0 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .common_content_w {
    max-width: 1124px;
    width: 100%;
  }
}

@media (min-width: 930px) and (max-width: 1199px) {
  .common_content_w {
    max-width: 926px;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .common_content_w {
    max-width: 692px;
    width: 100%;
  }

  .col-lg-12 {
    padding-left: 0px;
  }
}

@media (min-width: 768px) {
  .modal_dialog {
    margin: 30px auto;
    width: 700px;
  }

  .modal_content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }
}


@media (min-width: 500px) and (max-width: 767px) {
  .common_content_w {
    max-width: 500px;
    width: 100%;
  }

  .col-lg-12 {
    padding: 0px;
  }
}

@media (max-width: 499px) {
  .common_content_w {
    width: 100%;
    padding: 0px;
  }

  .col-lg-12 {
    padding: 0px;
  }
}