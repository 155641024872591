.Testimonials-top-div {
    margin-top: 80px;
}

.content-txt {
    margin: 20px 35px 20px 55px;
    line-height: 30px;
    color: #636362;
}

.top-content-box {
    max-width: 900px;
}

.select-tab {
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    padding: 20px;
}

.select-tab a {
    color: gold;
}

.select-css {
    border: none !important;
    box-shadow: none !important;
    appearance: auto;
}

.chat-box {
    background: #f6f6f6;
    padding: 30px;
    border-radius: 8px;
    margin: 18px 0;
}

.chat-txt {
    font-size: 18px;
    font-weight: bold;
    font-family: 'ManropeRegular';
    line-height: 24px;
}

.chat-txt2 {
    font-size: 20px;
    font-family: 'ManropeBold';
    line-height: 30px;
}

.chat-name {
    margin: 20px 0 0 0;
    font-weight: bold;
    display: flex;
}

.chat-date {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 20px;
}

.star-box {
    width: 60%;
    color: gold;
}

.star-num {
    width: 50%;
    text-align: right;
}

.c999 {
    color: #999;
}

.row-flex {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}


.easy_t_pagination {
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
}

.page-numbers {
    color: #9CBE3B;
    text-decoration: none;
    cursor: pointer;
}

.page-bottom {
    margin-right: 10px;
}

.container {
    display: flex;
    flex-direction: column;
}

.Loader-div>div {
    height: initial !important;
    width: initial !important;
}

.pointer {
    cursor: pointer;
    margin: 6px 6px;
}

.row-div {
    padding: 0;
    margin: 0;
}

/**radio-css***/
.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-weight: normal;
}

.custom-radio label:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #999;
    border-radius: 50%;
}

.custom-radio input[type="radio"]:checked+label:before {
    border-color: #A2BB0C;
}

.custom-radio label:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 0px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #A2BB0C;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease;
}

.custom-radio input[type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}

/* 单选框基本（未选中）的样式 */
/*input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none; 
  移除选中时的边框 
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-size: 100% auto;
      box-sizing: border-box;
      border: solid 1px #999;
      cursor: pointer;
  }*/

/* 单选按钮选中后增加的样式 */
/*input[type="radio"]:checked:before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      margin: -1px;
      border-radius: 10px;
      background-color: #A2BB0C;
      border: 0;
      cursor: pointer;
  }*/

.filter-box {
    display: flex;
    max-width: 1380px;
    margin: 0 auto;
    margin-bottom: 30px;
    gap: 4rem;
}

.items-style {
    font-size: 18px;
    color: #636362;
    display: flex;
    align-items: center;
    line-height: 24px;
}


/* starts css 1121*/
/* Basic styling for the dropdown */
.custom-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    max-width: 186px;
    box-shadow: 0 2px 15px rgba(221, 221, 221, 0.3);
    z-index: 1;
    border: 1px solid #ddd;
    left: 51px;
    top: 39px;
}

.stars {
    cursor: pointer;
}

.stars:hover {
    background-color: #ddd;
}

.active {
    font-weight: bold;
}

.starts-wrapper {
    display: flex;
    align-items: center;
}

.nav-item-tab .nav-link {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.selected-stars .nav-item-tab .bi-star-fill::before {
    color: gold;
}

.selected-stars {
    cursor: pointer;
    width: 177px;
    text-align: center;
}

.arrow-box {
    cursor: pointer;
}

.select-tab.dropdown-content {
    padding: 9px 3px;
    border-radius: 5px;
}

.nav-item-tab>a:hover,
.nav-item-tab>a:focus {
    position: relative;
    color: #ffffff;
    background: #a2bb0c;
    border-radius: 50px;
}

.starsfocus {
    position: relative;
    color: #ffffff !important;
    background: #a2bb0c;
    border-radius: 50px;
}

.starsfocus .stars-num {
    color: #ffffff;
    background: #a2bb0c;
}

.starsfocus span {
    color: #ffffff !important;
}

.nav-item-tab.stars {
    margin-bottom: 5px;
}

.nav-item-tab.stars:last-child {
    margin-bottom: 0px;
}

.arrow-box.active {
    transform: rotate(180deg);
}

.nav-item-tab>.nav-link {
    color: gold;
}

.nav-item-tab.stars.active .nav-link {
    position: relative;
    color: #fff;
    background: #A2BB0C;
    font-weight: bold;
    border-radius: 50px;
}

.nav-item-tab>a:hover .stars-num,
.nav-item-tab>a:focus .stars-num {
    color: #ffffff;
    background: #a2bb0c;
}

.nav-item-tab.stars.active .nav-link .stars-num {
    color: #ffffff;
    background: #a2bb0c;
}

.select2-search__field {
    display: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    width: 230px;
    height: 38px;
    line-height: 37px;
}

.select2-container .select2-selection--single {
    height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    /* background: url('./images/NL_icons/Angle_down.png'); */
    width: 20px;
    height: 20px;
    margin-left: -18px;
    margin-top: -13px;
    border-width: initial;
    border-color: #fff transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #fff transparent;
    border-style: initial;
    transform: rotate(180deg);
    margin-top: -11px;

}

.select-wrapper-box {
    margin-left: auto;
}

.slected-content {
    display: flex;
    align-items: center;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 12px;
}

.select2-results__option {
    padding-left: 12px;
}

.selected-stars .nav-item-tab .bi-star-fill::before:hover {
    color: #fff;
}

.show-start>span {
    color: #323232;
    font-size: 16px;
    font-family: 'ManropeRegular';
}

.nav-item-tab:hover .drop-stars span {
    color: #fff;
}

.nav-item-tab.stars.active .nav-link .drop-stars span {
    color: #fff;
}



.drop-stars>span {
    color: #323232;
    font-size: 16px;
    font-family: 'ManropeRegular';
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: normal;
}

.bi-interval-about {
    display: flex;
    gap: 3px;
    justify-content: center;
}

.bi-interval {
    display: flex;
    gap: 3px;
}

.no-data-wrapper {

    margin: 80px auto 120px;

}

.no-found .no-img {

    max-width: 130px;
    width: 100%;
}

.no-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.no-found-text {
    margin-top: 20px;
    font-size: 24px;
    color: #323232;
    text-align: center;
    line-height: 30px;
}

.select-down-new {
    background: #fff !important;
    width: 255px;
    height: 38px;
    line-height: 37px;
    outline: none;
    border-color: #ddd;
    border-radius: 3px;

    cursor: pointer;
    border: 1px solid #ddd;
    position: relative;
}

.select-wrapper-box {
    margin-left: auto;
    z-index: 1;
}


.select-down-item {
    background: #fff !important;
    width: 255px;
    line-height: 37px;
    border: 1px solid #ddd;

    position: absolute;
    left: -1px;
    top: 36px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 1;
    display: none;
}

.select-down-item .option-box {
    padding: 0px 12px;
}

.select-down-item .option-box:hover {
    background: #A2BB0C;
    color: #fff;
}

.typefocus {
    background: #A2BB0C;
    color: #fff;
}

.select-input {
    padding: 0px 10px;
}

.all-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.all-content.typeActive .down-icon {
    transform: rotate(180deg);
}

/* new css 1128*/

.stars-num {
    font-size: 12px;
    color: #323232;
    font-weight: normal;
    margin-left: 5px;


}

.drop-stars {
    display: flex;
    align-items: center;
}

.show-start {
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
}

.left-bi-star {
    display: flex;
    gap: 2px;
}

/* .starts-wrapper-box{
  
  } */

.starts-wrapper-item {
    border: 1px solid #ddd;
    /* background: #f6f6f6; */
    padding: 10px 10px;
    border-radius: 5px;
    margin-left: 0px;
}

.starts-wrapper-box {
    margin-left: 0px !important;
}

.starts-wrapper-item {
    font-weight: normal;
}

.starts-wrapper-item span {
    font-weight: bold;
}

.start-sticky-total div {
    margin: 0 10px;
    color: gold;
}

.starts-wrapper-box-div {
    margin-left: 0px !important;
    margin: 0 10px;
}

.star-content-display {
    display: flex;
    gap: 3px;
}

@media (max-width:1460px) {
    .filter-box {
        padding: 0px 4rem;
    }
}

@media (max-width:1120px) {
    .filter-box {
        display: flex;
        max-width: 1380px;
        margin: 0 auto 30px;
        gap: 2rem;
    }
}

@media (max-width:1080px) {
    .filter-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

    /* .select-wrapper-box{
          margin-left: 0px;
      } */

    .select-down {
        width: 340px !important;
    }

    .items-style.custom-radio:nth-child(2) {
        margin-left: auto;
    }

    .select-down-item,
    .select-down-new {
        width: 340px !important;
    }

}

.text-abroad {
    font-size: 14px;
    color: #999;
    font-weight: normal;
    margin-left: 15px;
    line-height: 20px;
}


@media (max-width:768px) {

    .chat-name {
        display: block;
    }

    .text-abroad {
        font-size: 14px;
        color: #999;
        font-weight: normal;
        margin-top: 15px;
        margin-left: 0px;
        line-height: 20px;
    }


    .filter-box {
        padding: 0px 2.5rem;
    }

    .dropdown-content {
        left: 49px;
        top: 39px;
    }

}

@media (max-width:670px) {
    .filter-box {
        display: block;
    }

    .select-down {
        width: 100% !important;
    }

    .items-style {
        margin-bottom: 2rem;
    }

    .starts-wrapper-toggle {
        margin-bottom: 2rem;
    }

    .select-down-item,
    .select-down-new {
        width: 100% !important;
    }
}




@media (max-width: 540px) {
    .items-style {
        margin-bottom: 1rem;
    }

    .starts-wrapper-toggle {
        margin-bottom: 1rem;
    }
}




@media (max-width: 400px) {

    .row-flex {

        flex-direction: column;
    }

    .chat-date {

        margin-top: 20px;
    }



}


/* banner-h1-title
  -------------------------------------------------------------------------- */
.top-banner.banner-title-content {
    max-width: 1440px;
    width: 100%;
    padding: 0 25px;
    margin: 0 auto;
    margin-top: 44px;
}

.about-title.banner-h1-title {
    position: static;
    width: 100%;
    color: #363635;
}

.nav-item-tab {
    border-radius: 50px;
    background: #f6f6f6;
    margin: 0px 6px;
}

.nav-item-tab .nav-link {
    font-size: 14px;
}

.nav-item-tab>a {
    font-weight: bold;
    color: #9D9C9C;
}

.c-name {
    font-size: 18px;
    color: #a2bb0c;
}


select option:checked {
    background-color: #a2bb0c;
    color: white;
    /* 可选，设置文字颜色 */
}

/* select option:hover {
    background-color: green;
    color: white;

} */

.select-down {
    background: #fff !important;
    width: 230px;
    height: 38px;
    line-height: 37px;
    outline: none;
    border-color: #ddd !important;
    border-radius: 3px;
    padding: 0px 12px;
    cursor: pointer;
    border: 1px solid #ddd;
}

.select-stars {
    text-align: center;
}