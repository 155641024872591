/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"); */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  /* overflow: hidden!important; */
}
body {
  letter-spacing: 1px;
  /* overflow: hidden!important; */
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "ManropeBold";
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
ul,
li,
button,
select,
option {
  font-family: "ManropeRegular", sans-serif;
}
:root {
  --color-bg-primary: #a2bb0c;
  --color-bg-secondary: #007f8f;
  --color-bg-white: #fff;
  --color-text-primary: #a2bb0c;
  --color-text-secondary: #007f8f;
  --color-text-dark: #363635;
  --color-text-white: #fff;
}
.bg__primary {
  background-color: var(--color-bg-primary);
}
.bg__secondary {
  background-color: var(--color-bg-secondary);
}
.text__primary {
  color: var(--color-text-primary);
}
.text__secondary {
  color: var(--color-text-secondary);
}
.text__dark {
  color: var(--color-text-dark);
}
.text__white {
  color: var(--color-text-white);
}
.btn__green {
  background-color: var(--color-bg-primary);
  color: var(--color-text-white);
  border-radius: 4px;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
}
.btn__green:hover {
  background-color: var(--color-bg-secondary);
  color: var(--color-text-white);
  transition: 0.5s;
}
.btn__green a {
  text-decoration: none;
  font-size: 14px;

  font-weight: 600;
  letter-spacing: 1px;
  color: var(--color-text-white);
}
.btn__green a:hover {
  text-decoration: none;
  color: var(--color-text-white);
}

.pxy-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .pxy-80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.pxy-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .pxy-40 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}









.pb-100 {
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-40{
  padding-top: 40px;
}
/* Button white */
.btn__white {
  border-radius: 50px;
  background: #fff;
  color: #a2bb0c;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
}
.btn__white:hover {
  background: #a2bb0c;
  color: #fff;
  transition: 0.3s;
}
.btn__white:hover a{
  background: #a2bb0c;
  color: #fff;
  transition: 0.3s;
}



.btn__white > a {
  border-radius: 50px;
  background: #fff;
  color: #a2bb0c;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
}
.btn__white > a:hover {
  background: #a2bb0c;
  color: #fff;
  transition: 0.3s;
}

/* SVG */

.line_svg_block {
  text-align: center;
}
.steps .svg_arrow .line,
.steps .svg_arrow .arrow {
  stroke: #fff;
  stroke-width: 14;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.steps .svg_arrow .line {
  stroke-dasharray: 1040;
  stroke-dashoffset: 1040;
  transition: none;
}
.steps .arrow2 .path2 {
  stroke-dasharray: 1780;
  stroke-dashoffset: 1780;
  transition: none;
}
.steps .arrow3 .path2-03 {
  stroke-dashoffset: 1780;
  stroke-dasharray: 1780;
}
.steps .svg_arrow .arrow,
.steps .svg_arrow .words {
  opacity: 0;
  transition: none;
}
.steps .svg_arrow.visible .path2,
.steps .svg_arrow.visible .line {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s ease;
}
.steps li .svg_arrow.visible .words {
  opacity: 1;
  transition: opacity 0.8s ease 0.4s;
}
.steps li .svg_arrow.visible .arrow {
  opacity: 1;
  transition: opacity 0.5s ease 1.2s;
}
/* 02 */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.path {
  fill: none;
}
.path1-first,
.path1-03,
.path1-01,
.path1 {
  opacity: 0.24;
  fill: none;
  stroke: #a3ba00;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 8;
  enable-background: new;
}
.path2 {
  stroke: #a2bb0c;
  stroke-width: 3px;
  stroke-dasharray: 1094;
  stroke-dashoffset: 1094;
  animation: animation 2s linear forwards;
}
.arrow2-first .path2-first {
  stroke: #a2bb0c;
  stroke-width: 3px;
  stroke-dasharray: 632;
  stroke-dashoffset: 632;
  animation: animation2-first 2s linear forwards;
}
.arrow2 .path2-02 {
  stroke: #a2bb0c;
  stroke-width: 3px;
  stroke-dasharray: 1780;
  stroke-dashoffset: 1780;
  animation: animation2 2s linear forwards;
}
.arrow3 .path2-03 {
  stroke: #a2bb0c;
  stroke-width: 3px;
  stroke-dasharray: 1780;
  stroke-dashoffset: 1780;
  animation: animation3 2s linear forwards;
}
.st1 {
  fill: #a3ba00;
}
.st2 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
@keyframes animation {
  from {
    stroke-dashoffset: 1094;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animation2-first {
  from {
    stroke-dashoffset: 632;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animation2 {
  from {
    stroke-dashoffset: 1780;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animation3 {
  from {
    stroke-dashoffset: 1780;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.fadeInAnim .svg_arrow .circle,
.fadeInAnim .svg_arrow .circle animateMotion,
.fadeInAnim .svg_arrow .path2 {
  /* opacity: 0; */
  transition: none;
  display: none;
}
.fadeInAnim .svg_arrow.visible .circle,
.fadeInAnim .svg_arrow.visible .circle animateMotion {
  /* opacity: 1;   */
  display: block;
}
.fadeInAnim .svg_arrow.visible .path2 {
  /* opacity: 1;   */
  /* animation: animation 3s linear forwards; */
  display: block;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s ease;
}
/* arrow2-first */
.fadeInAnim .svg_arrow .circle,
.fadeInAnim .svg_arrow .circle animateMotion,
.fadeInAnim .svg_arrow .path2-first {
  /* opacity: 0; */
  transition: none;
  display: none;
}
.fadeInAnim .svg_arrow.visible .circle,
.fadeInAnim .svg_arrow.visible .circle animateMotion {
  /* opacity: 1;   */
  display: block;
}
.fadeInAnim .svg_arrow.visible .path2-first {
  /* opacity: 1;   */
  /* animation: animation 3s linear forwards; */
  display: block;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s ease;
}
/* arrow2 */
.fadeInAnim .svg_arrow .circle,
.fadeInAnim .svg_arrow .circle animateMotion,
.fadeInAnim .svg_arrow .path2-02 {
  /* opacity: 0; */
  transition: none;
  display: none;
}
.fadeInAnim .svg_arrow.visible .circle,
.fadeInAnim .svg_arrow.visible .circle animateMotion {
  /* opacity: 1;   */
  display: block;
}
.fadeInAnim .svg_arrow.visible .path2-02 {
  /* opacity: 1;   */
  /* animation: animation 3s linear forwards; */
  display: block;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s ease;
}
/* arrow3 */
.fadeInAnim .svg_arrow .circle,
.fadeInAnim .svg_arrow .circle animateMotion,
.fadeInAnim .svg_arrow .path2-03 {
  /* opacity: 0; */
  transition: none;
  display: none;
}
.fadeInAnim .svg_arrow.visible .circle,
.fadeInAnim .svg_arrow.visible .circle animateMotion {
  /* opacity: 1;   */
  display: block;
}
.fadeInAnim .svg_arrow.visible .path2-03 {
  /* opacity: 1;   */
  /* animation: animation 3s linear forwards; */
  display: block;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s ease;
}
.bg_div {
  width: 100%;
  height: auto;
  padding-left: 29%;
}
.main-title {
  margin: 10px 0;
}
@media (max-width: 1600px) {
  .bg_div {
    padding-left: 48%;
  }
}
@media (max-width: 1300px) {
  .fadeInAnim .svg_arrow {
    display: none;
  }
}
.breadcrumb {
  padding: 0 !important;
  margin-bottom: 0 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, ">") !important;
}
.breadcrumb-item a {
  color: var(--color-text-primary);
  text-decoration: none;
}
.breadcrumb-item a:hover {
  color: var(--color-text-secondary);
}
.ReactModal__Content {
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  width: 45% !important;
  max-height: 100%;
  overflow-y: auto;
  text-align: center !important;
  margin-top: 20px !important;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, .49) !important;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}

.ReactModal__Overlay {
  z-index: 999;
  background: rgb(0,0,0,0.4) !important;
}
.ReactModal__Content h2 {
  font-weight: 700 !important;
  margin-top: 1rem;
  font-family: ManropeBold;
}
.ReactModal__Content p {
  font-size: 18px;
  text-align: left;
  line-height: 30px;
}
.ReactModal__Body--open {
  overflow: hidden !important;
}

.sliderBtn .swiper-button-next,.sliderBtn .swiper-button-prev{
  color: #FFFFFF;
  width: 45px;
  font-size: 2rem;
  background: #a2bb0c;
  border-radius: 100%;
  height: 45px;
}
.sliderBtn .swiper-button-next:after, .sliderBtn .swiper-button-prev:after {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}
.sliderBtn .swiper-button-next.swiper-button-disabled, .sliderBtn .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}


@media (min-width: 300px) and (max-width: 600px) {
  .ReactModal__Content {
    width: 90%!important;
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    max-height: 100%;
    overflow-y: auto;
  }


}
@media (min-width: 1440px){
  .container {
    width: 100% !important;
    max-width: 1440px !important;
  }
}

@media only screen and (max-width: 1399px) and (min-width: 1301px) {
  .top-svg{
    position: absolute;
    right: 0;
    bottom: -46%;
    overflow: hidden;
  }
  .bg_div{
    width: 910px;
    height:auto;
	}
}
@media only screen and (max-width: 1439px) and (min-width: 1400px) {
  .top-svg{
    position: absolute;
    right: 0;
    bottom: -38%;
    overflow: hidden;
  }
}
@media only screen and (max-width: 1479px) and (min-width: 1440px) {
  .top-svg{
    position: absolute;
    right: 0;
    bottom: -38%;
    overflow: hidden;
  }
  
}
@media (min-width: 1480px) {
  .top-svg{
    position: absolute;
    right: 0;
    bottom: 15%;
    overflow: hidden;
  }
}
.btn__green_outline {
  border: 2px solid var(--color-bg-primary);
  border-radius: 4px;
  padding: 10px !important;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: 'ManropeBold';
  color: var(--color-bg-primary);
  white-space: nowrap;
}
.btn__green_outline:hover {
  background-color: var(--color-bg-secondary);
  color: var(--color-text-white);
  /* transition: 0.5s; */
  border: 2px solid var(--color-bg-secondary);
}
.btn__green_outline a {
  text-decoration: none;
  font-size: 14px;

  font-weight: 600;
  letter-spacing: 1px;
  color: var(--color-text-white);
}
.btn__green_outline a:hover {
  text-decoration: none;
  color: var(--color-text-white);
}

  /* title-position function */
.title-position-right{
  text-align: right !important;
}
.title-position-center{
  text-align: center !important;
}
.title-position-left{
  text-align: left !important;
}