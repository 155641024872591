.description__container h2 {
  font-size: 30px;
  color: #363635;
  line-height: 32px;
  font-weight: bold;
  font-family: "ManropeBold";
  padding: 0 35px 0;
}
.description__container p {
  margin: 20px 35px 20px 35px;
  line-height: 30px;
  color: #636362;
  font-size: 18px;
}
.pxy_30{
  padding-bottom: 30px;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .description__container h2 {
    font-size: 30px;
    line-height: 32px;
    padding: 0;
  }
  .content__area p {
    margin: 20px 0;
  }
  .padding_0_15{
    padding: 0 15px;
  }
  .padding_0{
    padding: 0;
  }
}
