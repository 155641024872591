.cardSlider__title__area {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f6f6f6;
}
.cardSlider__title__area h3 {
  font-family: "ManropeBold";
  font-size: 88px;
  font-weight: bold;
  color: #363635;
  padding-bottom: 20px;
  line-height: 90px;
  text-align: center;
}
.cardSlider_title_contents {
  padding: 0 25px;
}
@media screen and (max-width: 768px) {
  .cardSlider__title__area h3 {
    font-size: 68px;
    line-height: 80px;
    text-align: left;
  }
}
@media screen and (max-width: 425px) {
  .cardSlider__title__area h3 {
    font-size: 40px;
    line-height: 45px;
    text-align: left;
  }
}

@media screen and (max-width: 479px) {
  /* .cardSlider__title__area {
    padding-left: 1rem;
  } */
}

