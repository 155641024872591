.banner__area {
  padding: 80px 0 0 0;
}

.banner__container h2 {
  color: #363635;
  font-family: ManropeBold;
  font-weight: 700;
  padding: 0 40px;
  font-size: 30px;
  line-height: 32px;
  padding: 0;
  margin-bottom: 20px;
}

.banner__container span {
  color: #a2bb0c;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 20px;
  display: block;
  font-size: 20px;
}

.banner__area_p80 {
  padding: 80px 0 0 0;
}

.banner__area_p40 {
  padding: 40px 0 0 0;
}

.banner__content {
  margin: 50px 35px 20px 50px;
  color: #636362;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 30px;
}

.banner__content p {
  line-height: 30px;
  color: #636362;
  font-size: 18px;
  letter-spacing: 1px;
}

.banner__content button {
  margin: 1.8rem 0;
}

.img__container {
  padding: 80px 0 20px 0;
}

.img__container img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.ebcContent a {
  display: block;
  color: #A2BB0C;
  font-size: 26px;
  margin-bottom: 20px;
  font-weight: bold;
  text-decoration: underline;
  width: fit-content;
}

.ebcContent a:hover {
  color: #007f8f;
  text-decoration: underline;
}

/* ==== MEDIA QUERIES (SMALL DEVICES) ==== */
@media screen and (max-width: 768px) {
  .banner__content {
    margin: 20px 0;
  }

  .banner__container h2 {
    font-size: 30px;
    line-height: 32px;
  }

  .img__container {
    padding: 20px 0;
  }

  .banner__area_p80 {
    padding: 40px 0 0 0;
  }
}

@media screen and (max-width: 425px) {
  .img__container {
    padding: 20px 0;
  }
}