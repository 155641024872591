.item-special {
  margin-top: 80px !important;
}
.card__image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  max-width: 100%;
}
.card__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
.card__image img {
  max-width: 100%;
  border-radius: 8px;
  width: 100%;
  height: auto;
}
.card__image:before {
  content: "";
  z-index: 0;
  width: 80%;
  height: 50%;
  opacity: 0;
  background-color: transparent;
  transition: opacity 0.3s cubic-bezier(0.38, 0.41, 0.27, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0.8rem 3.2rem rgb(0 0 0 / 50%);
}
.card__image:after {
  content: "";
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: linear-gradient(
      transparent 0%,
      rgba(0, 0, 0, 0.2) 15%,
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0.2) 75%,
      #000
    )
    0 0 / auto 150%;
  transition: background-position 0.3s cubic-bezier(0.38, 0.41, 0.27, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}
.single__card__button {
  z-index: 1;
  height: 100%;
  flex-flow: column;
  display: flex;
  position: absolute;
  top: 0;
}
.single__card__button button {
  margin: 20px;
}
.singleCard__content {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0px 20px;
  color: #fff;
  z-index: 1;
}
.singleCard__content h4 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "ManropeBold";
  line-height: 40px;
}
.singleCard__content p {
  line-height: 28px;
  overflow: hidden;
}

.hexa__card__container {
  margin-top: -10px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f6f6f6;
}
.tricard__title__area {
  text-align: right;
}
.tricard__title__area span {
  color: #a2bb0c;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 20px;
  display: block;
  font-size: 20px;
  font-family: "ManropeBold";
}
.tricard__title__area h6 {
  font-size: 88px;
  color: #363635;
  padding-bottom: 40px;
  line-height: 93px;
  font-weight: bold;
  font-family: "ManropeBold";
}

/* ==== MEDIA QUERIES (SMALL DEVICES) ==== */



@media only screen and (max-width: 2560px) and (min-width: 1025px){
  .individual__tri__card:nth-child(2){
    margin-top: 30px;
  }
  .individual__tri__card:nth-child(5), .individual__tri__card:nth-child(8),  .individual__tri__card:nth-child(11), .individual__tri__card:nth-child(14),  .individual__tri__card:nth-child(17){ 
    margin-top: 5px;
  }
  .individual__tri__card:nth-child(4), .individual__tri__card:nth-child(6), .individual__tri__card:nth-child(7), .individual__tri__card:nth-child(9), .individual__tri__card:nth-child(10), .individual__tri__card:nth-child(12), .individual__tri__card:nth-child(13), .individual__tri__card:nth-child(15){
    margin-top: -30px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .card__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .cardWithTitleImageDescButton__container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tricard__title__area h6 {
    font-size: 88px;
    line-height: 93px;
    padding-bottom: 30px;
    text-align: left;
  }
  .tricard__title__area span {
    text-align: left;
  }
  .individual__tri__card:nth-child(2){
    margin-top: 30px;
  }
  .individual__tri__card:nth-child(4),  .individual__tri__card:nth-child(6), .individual__tri__card:nth-child(8),  .individual__tri__card:nth-child(10), .individual__tri__card:nth-child(12),  .individual__tri__card:nth-child(14), .individual__tri__card:nth-child(16),  .individual__tri__card:nth-child(18){ 
    margin-top: 10px;
  } 

  .individual__tri__card:nth-child(5), .individual__tri__card:nth-child(3), .individual__tri__card:nth-child(7), .individual__tri__card:nth-child(9), .individual__tri__card:nth-child(11), .individual__tri__card:nth-child(13), .individual__tri__card:nth-child(15), .individual__tri__card:nth-child(17){
    margin-top: -20px;
  }
}
@media screen and (max-width: 1024px) {
  .title_width_left{
    text-align: left !important;
  }
}
@media screen and (max-width: 767px) {
  .card__container {
    grid-template-columns: 1fr;
    gap: 4rem;
    margin-top: 20px;
  }
  .cardWithTitleImageDescButton__container { 
    padding-bottom: 30px;
  }
  .tricard__title__area {
    text-align: left;
  }
  .tricard__title__area h6 {
    font-size: 48px;
    line-height: 52px;
    padding-bottom: 30px;
    text-align: left;
    padding: 0;
  }
  .tricard__title__area span {
    text-align: left;
  }
  .individual__tri__card{
    margin-bottom: 0px;
  }
}


