
.modal-header .close{margin-top: -60px;
    margin-right: -15px;}
.modal-dialog-centered {
    display: -webkit-box;
    display: -webkit-flex; 
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}
.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px);
}
.add{height:100%;overflow:hidden;}

/*.about-title{
	position: absolute;
    bottom: 29%;
    left: 21%;
    text-align: left;
    font-size: 88px;
	 font-family: "ManropeBold";
	line-height: 100px;
}*/
.ab-pc-img{display: block;}
.ab-mb-img{display: none;}

.temp-white-block{max-width:1440px; margin: 80px auto; width: 100%; padding: 0 40px;}
.temp-white-block span{color: #a2bb0c;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 20px;
    display: block;
    font-size: 20px;
}

/*.content-txt{margin: 20px 35px 20px 35px;line-height: 30px;
color: #636362;
}*/

.temp-gray-block{background: #f6f6f6; padding: 20px 0;}
.img-content-box{max-width:1440px; margin: 0 auto; width: 100%; margin-top: 40px;}
.ab-title-box{  padding: 0 40px;}
.ab-title-box span{color: #a2bb0c;
    font-weight: bold;
    text-transform: uppercase;
padding-bottom: 20px;
    display: block;
    font-size: 20px;
}
.bg1{background: url('../images/common/Zurich-2132.jpg') no-repeat; background-size: 100%; background-position: center;}
.bg2{background: url('../images/common/Basel-3168.jpg') no-repeat; background-size: 100%; background-position: center;}
.bg3{background: url('../images/common/Basel-3703.jpg') no-repeat; background-size: 100%; background-position: center;}
.bg4{background: url('../images/common/Lausanne-5099.jpg') no-repeat; background-size: 100%; background-position: center;}
.bg5{background: url('../images/common/Zug-2324.jpg') no-repeat; background-size: 100%; background-position: center;}
.bg6{background: url('../images/common/Basel-3887.jpg') no-repeat; background-size: 100%; background-position: center;}
.bg7{background: url('../images/common/rob-ariport-6155.jpg') no-repeat; background-size: 100%; background-position: center;}
.item-special{margin-top: 80px !important;}
.item {
	position: relative;
	width: 100%;
	background-color: white;
	min-height: 500px;
	padding: 0px;
	margin: 30px 0px;
	/*box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 10px 0;*/
	border-radius: 6px;
	text-align: center;
	    color: white;
}
.item h3{    font-size: 30px;
    font-family: 'ManropeBold';
    margin-bottom: 16px;
}
.item:hover {
	/*opacity: 0.5;*/
}

.txt-block{    position: absolute;
    bottom: 20px;
    left: 0;
    text-align: center;
    width: 100%;
    padding: 0px 20px;
    color: #fff;
	z-index: 1;
}
.txt-block h3{    font-size: 30px;
    font-family: 'ManropeBold';
    margin-bottom: 16px;}
.txt-block > p{line-height: 28px; max-height: 108px;
    overflow: hidden;}
/*.top-card-ab{margin: 20px 0;}*/
.top-card-ab:nth-child(even){
    margin-top: 30px;
}
.item span {
      position: absolute;
    bottom: -23px;
    left: 45%;
    font-size: 45px;
    color: #a2bb0c;
    width: 45px;
    height: 45px;
    background: white;
    border-radius: 50%;
	cursor: pointer;
}

.ab-content-01,.ab-content-02,.ab-content-03,.ab-content-04,.ab-content-05,.ab-content-06,.ab-content-07{position: fixed; top:0;left: 0; z-index: 999;width: 100%;height: 100%; display: none;}
.pop-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   background:rgb(0,0,0,0.4);
	  /*  border: 1px solid red;*/
}
.bg{z-index:99;}

@media (max-width: 1670px) {
    .txt-block > p{
        max-height: none;
        overflow: visible;
    }
}
@media (max-width: 992px) {
	.ab-pc-img{display: none;}
    .ab-mb-img{display: block;}
	.about-title{position: absolute;
    bottom: 14%;
    left: 5%;
    font-size: 40px;
    font-family: "ManropeBold";
    line-height: 50px;
}

.content-txt{margin: 20px 0px;}
.item {
	min-height: 500px;}
}
@media (min-width:769px) and  (max-width:820px) {
    .item {
        min-height: 475px;}
   
}
@media (max-width:768px) {
.item h3 {
    font-size: 30px;
    font-family: 'ManropeBold';
	color: #fff;
}
.item {
	min-height: 420px;}
	.item-special {
    margin-top: 0px !important;
}
.ab-title-box span{
    text-align: left;
}
}

@media (max-width:500px) {
	
.item {
	min-height: 400px;}
}