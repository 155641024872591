.banner__area {
  padding: 80px 0 0 0;
}

.banner__container h2 {
  color: #363635;
  font-family: ManropeBold;
  font-weight: 700;
  padding: 0 40px;
  font-size: 30px;
  line-height: 42px;
  padding: 0 49px 20px;
}

.banner__container span {
  color: #a2bb0c;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 20px;
  display: block;
  font-size: 20px;
}

.banner__area_p80 {
  padding: 50px 0 0 0;
}
.banner__area_m50 {
  margin: 50px 0 80px 0;
}
.banner__area_bottom_p50 {
  padding: 0 0 50px 0;
}

.banner__area_p40 {
  padding: 40px 0 0 0;
}

.banner__content {
  margin: 0px 35px 0px 50px;
}

.banner__content p {
  line-height: 30px;
  color: #636362;
  font-size: 18px;
  letter-spacing: 1px;
}

.banner__content p img {
  width: 100%;
  height: auto;
}

.banner__content span img {
  width: 8px;
  height: auto;
}

.banner__content button {
  margin: 1.8rem 0;
}

.img__container {
  padding: 0px 0 20px 0;
}

.img__container img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

/* ==== MEDIA QUERIES (SMALL DEVICES) ==== */
@media screen and (max-width: 768px) {
  .banner__content {
    margin: 20px 0;
  }

  .banner__container h2 {
    font-size: 30px;
    line-height: 38px;
    padding: 0;
  }

  .img__container {
    padding: 20px 0;
  }

  .banner__area_p80 {
    padding: 0px 0 0 0;
  }
}

@media screen and (max-width: 425px) {
  .img__container {
    padding: 20px 0;
  }
}