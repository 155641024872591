.cardSlider__container {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%!important;
}
.cardSlider__image {
  position: relative;
}
.cardSlider__image img {
  width: 100%;
  border-radius: 8px;
  height: auto;
}
.cardSlider__top__area {
  position: absolute;
  top: 0;
  margin: 20px;
  z-index: 2;
}
.cardSlider__contents {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: left;
  width: 100%;
  padding: 0px 20px;
  color: #fff;
  z-index: 1;
}
.cardSlider__contents h4 {
  max-width: 100%;
  font-weight: bold;
  line-height: 30px;
  color: #fff;
  font-size: 20px;
  font-family: "ManropeBold";
}
.cardSlider__title {
  position: absolute;
  bottom: 0;
  margin: 30px;
}

.cardSlider__contents button {
  padding: 10px 15px;
  margin: 20px 20px;
  border-radius: 50px;
  background: #fff;
  color: #a2bb0c;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}
.cardSlider__area:nth-child(even) {
  margin-top: 20px !important;
}
.slider__single__card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin: 30px 0;
}
.slider__single__card h4 {
  font-size: 20px;
}

/* @media (min-width: 571px) and (max-width: 992px) {
  .cardSlider__area:nth-child(3){
    margin-right: 0px;
  }
} */