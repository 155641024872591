/* .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.spinner__background {
  background-color: var(--color-bg-primary);
}

.spinner__border__color {
  border: 6px solid var(--color-bg-primary);
  border-right-color: transparent;
}

.loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  flex-direction: column;
}

.gif_title {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.loading_gif {
  max-width: 240px;
}

@media (max-width: 992px) {

  .spinner__background,
  .spinner__border__color {
    width: 3rem !important;
    height: 3rem !important;
  }
}