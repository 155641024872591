header .header-menus {
    padding-top: 0px;
    margin-bottom: 0;
}
.header-menus .text-color{
    color: #363635;
}
.menu-controller-text{
    font-weight: bold;
}
.about_us_menu_wrap.services_menu_wrap.active,
.header-menus-wrap.active{
    top: 151px;
}
.about_us_menu_wrap{
    top: 100px;
    width:470px;
    left: 57%;
}
.menu-list li{
    text-align: left;
}
.services_menu_wrap{
    top: 100px;
    width: 680px;
    left: 53.5%;
}
.header-menus-wrap .menu-list ul li a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
}

.header-menus-wrap .menu-list ul li a .arrow_span {
    width: 12px;
    height: 12px;
    background-image: url(../images/arrow_01_.png);
    margin-right: 10px;
    margin-top: 10px;
    line-height: 30px;
}
.header-menus-wrap .menu-list ul li a .arrow_span.active{
    margin-top: 8px;
}
@media (max-width: 768px) {
header .header-menus li:last-child {
    border: none;
}
}
.header-menus-wrap ul li>a {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
    padding: 12px 0;
}
header .header-menus li{
    line-height: 28px;
}
.about_us_menu_wrap.services_menu_wrap{
    top: 100px;
    width: 440px;
    left: 76%;
}
header .header-menus li a:hover{
    color: #007F8F;
}
header .header-menus li .menu-controller-text span{
    display: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 10px solid #a3ba00;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
}
header .header-menus li .menu-controller-text {
    padding: 0px 20px 20px 20px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    line-height: 24px;  
}
.menu-controller-text{
 line-height: 24px;
}
.p-text{
    line-height: 28px;
}
.header-menus-wrap .menu-list ul li a:hover {
    color: #007F8F !important;
}
.search-blcok{
    z-index: 100000;
}

.mb-btn{
    background-color: #A2BB0C !important;
    color: #fff !important;
    margin: 10px 0px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
}
.map-icon{
    border: 1px solid #efefef; 
     border-radius: 5px; 
     text-align: center; 
     width: 40px; 
     height: 40px; 
     margin: 0 !important; 
     padding: 6px;
    }
.header-menus-wrap .menu-list:last-child, .header-menus-wrap h4:first-child{
  padding-left: 0px !important;
}
.header-menus-wrap ul li>a img {
    margin-right: 10px !important;
    margin-top: 7px;
}
.menu-content{
    display: flex;
    /* justify-content: space-between; */
}
.menu-content a img{
    margin-right: 10px !important;
}
.menu-content a:first-child{
    width: 52%;
    margin-right: 37px;
}

.menu-content-3 a:first-child{
    width: 48%;
    margin-right: 40px;
}
.menu-content a{
    width: 48%;
}
.menu-first-content a:first-child{
    width: 100%;
    margin-right: 0px;
}
.lg-nav-fade {
    width: 100%;
    position: absolute;
    /* top: 100px; */
    left: 0;
    height: 200px;
    background-color: rgba(0,0,0,0);
}
.header-nav{
    width: 45%;
}
header .header-menus li .menu-controller-text{
    line-height: 60px;
}  
header .header-menus{
    margin-right: 0px;
}  

@media (max-width: 1700px) {
    .about_us_menu_wrap.services_menu_wrap{
        left: 80%;
    }
    .about_us_menu_wrap{
        left:58% ;
    }
    }

@media (max-width: 1600px) {
    .services_menu_wrap{
        left: 52%;
    }

    .about_us_menu_wrap.services_menu_wrap{
        left: 83%;
    }
}



@media (max-width: 1480px) {
    .header-menus-wrap,
    .about_us_menu_wrap.services_menu_wrap,
    .about_us_menu_wrap{
        top: 129px;
    }

    .about_us_menu_wrap.services_menu_wrap.active, .header-menus-wrap.active{
        top: 180px;
    }
 
 
}

@media screen and ( min-width: 1380px ) and ( max-width: 1480px ) {
    .about_us_menu_wrap{
        left: 57%;
    }
    .services_menu_wrap{
        left: 49.5%;
       
    }
}

@media (max-width: 1360px) {
    .about_us_menu_wrap{
        left: 54%;
    }

    .about_us_menu_wrap.services_menu_wrap{
        left: 82%;  
    }
    .services_menu_wrap{
        left: 45%; 
    }  
}

@media (max-width: 1280px) {
    .services_menu_wrap {
        left: 43%;
    }
    .about_us_menu_wrap {
        left: 50%;
    }
    .about_us_menu_wrap.services_menu_wrap{
        left: 79%;  
    }
}

@media (max-width: 1240px) {
    .services_menu_wrap {
        left:40%;
        
    }
   
  
}

@media (max-width: 1182px) {
    .about_us_menu_wrap.services_menu_wrap.active, .header-menus-wrap.active{
        top: 159px;
    }
    .header-menus-wrap, .about_us_menu_wrap.services_menu_wrap, .about_us_menu_wrap{
        top: 59px;
    }
    .services_menu_wrap{
        width: 630px;
        left: auto;
        right:3%;
    }
    .about_us_menu_wrap.services_menu_wrap{
        width: 400px;
        left: 78%;
    }
    .about_us_menu_wrap{
        width: 400px;
        left: auto;
        right:5%;
    }
    
    header .header-menus li .menu-controller-text{
        line-height: 20px;
    }
    .header-nav{
        height: 56px;
        width: 100%;
    }
    .header-nav.header-height{height: 257px;

    }
}

@media (max-width: 942px) {
    .header-menus-wrap, .about_us_menu_wrap.services_menu_wrap, .about_us_menu_wrap{
        top: 58px;
    }

    header .header-menus li {
        display: inline-block;
    }
    .header-nav.header-height {
        height: 277px;
    }
    header .header-menus li:hover .header-menus-wrap, 
    header .header-menus li:hover .menu-controller-text span{
        display: block;
    }
    header .header-menus li .menu-controller-text{
        line-height: 18px;
    }
    .about_us_menu_wrap {
        left: 50%;
    }
    
    /* .services_menu_wrap {
        width: 100%;
        left: 0%;
    } */
    .about_us_menu_wrap.services_menu_wrap{
        left: 73%;
    }
    .header-nav{
        height: 76px;
    }
}
@media (max-width: 830px) {
    .about_us_menu_wrap {
        left: 45%;
    }
}
@media (max-width: 768px){
    .header-menus-wrap,
    .about_us_menu_wrap.services_menu_wrap,
    .about_us_menu_wrap{
        width: 100%;
    }
    header .header-menus {
        display: block;
        position: fixed;
        top: 20px;
        left: 0;
        height: 100%;
        background: #fff;
        width: 100%;
        padding: 0 15px;
        overflow-y: scroll;
        padding-bottom: 550px;
    }
    .nav-link {
        display: block;
        padding: 0.8em 1em;
    }
    header .header-menus li {
        display: block;
        padding: 15px 0;
        /* border-bottom: 1px solid #eee; */
    }
    header .header-menus li .menu-controller-text{
    padding: 5px; 
    font-size: 18px;
    cursor: pointer;
    position: relative;
    line-height: 24px;
    }
 
    header .header-menus li:hover .menu-controller-text span{
        display: none;
    }
    header .header-menus li .menu-list li{
        border-bottom: 1px solid #eee;
    }
}
/* 0504 */
.header-logo-outer .header-logo{
    width: 330px;
    }
    
    .start-sticky{
    border-bottom: initial;
    padding: 0px;
    background-color: #fff;
    }
    
    .start-sticky-wrap{
    padding: 0px;
    padding-top: 23px;
    max-width: 1440px;
    text-align: right;
    margin: 0 auto;
    /* padding-right: 20px; */
    }
    
    .header-logo-outer{
    margin-top: -40px;
    z-index: 40;
    }
    
    @media (max-width: 1480px){
    
    .header-logo-outer{		
    width: 250px;
    }
    .start-sticky-wrap {
        padding: 0px;
        padding-top: 23px;
        max-width: 1440px;
        text-align: right;
        margin: 0 auto;
        padding-right: 20px;
    }
    
    }
    
    @media (min-width: 942px) and (max-width: 1200px){
    
    header .header-logo img {
    max-width: 300px;
    width: 100%;
    }
    
    }
    
    @media (max-width: 1182px){
    
    .header-logo-outer{
    margin-top:5px;
    }
    
    .start-sticky-wrap{
    padding-top: 12px;
    padding-bottom: 6px;
    }
    
    .header-nav.header-height{
    height: 276px;
    }
    
    }
    
    @media (max-width: 940px){
    
    .header-nav.header-height{
    height: 294px;
    }
    
    }
    
    
    @media (max-width: 768px){
    
    .top-banner.active{
    /* margin-top:100px; */
    }
        
    .start-sticky-wrap{
    padding-top: 8px;
    text-align: center;
    padding-right: 0px;
    padding-bottom: 5px;	
    }
    
    header .header-logo img{
    padding: 0px;
    width: 180px;
    }
    
    .start-sticky{
    padding-right: initial;
    }
    
    .header-logo-outer{
    margin-top: 0px;
    }
    
    .start-sticky-total{
    display: flex;
    margin-left: 0px;
    }
    
    .header-bottom{
    display: flex;
    align-items: center;
    height: 68px;
    }
    
    .nav-drill.active {
    top: auto;
    margin-top: 0px;
    
    }
    
    }
    
    @media (max-width: 350px){
    
    header .header-logo img {
    width: 140px;
    }
    
    }	
    /* 0504 end */