.language__block {
  flex-basis: calc((100% / 3) - 4rem);
  border-radius: 5px;
  background: #f6f6f6;
  padding: 40px;
  text-align: center;
  margin-bottom: 50px;
  height: 320px;
}

.lang__title {
  border-bottom: 1px solid #a0a0a0;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lang__title span {
  font-weight: bold;
  color: #000;
  font-size: 24px;
  letter-spacing: -0.1px;
  line-height: 32px;
}

.lang__title img {
  width: 35px;
  margin-right: 12px;
}

.languages__area ul li a {
  color: #000;
  font-size: 24px;
  transition: 0.7s;
}

.languages__area ul li {
  padding-top: 30px;
}

.languages__area ul li a:hover {
  color: #007f8f;
}

.heading__area h1 {
  font-size: 88px;
  font-weight: bold;
  line-height: 100px;
  color: black;
  margin: 10.25rem 0 6.25rem 0;
}

@media screen and (max-width: 1024px) {
  .heading__area h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 7.5rem 0 5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .heading__area h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 5rem 0 5rem 0;
  }
}

@media screen and (max-width: 425px) {
  .heading__area h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 5rem 0 5rem 0;
  }
}



.language_content {
  max-width: 1100px;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-flow: row wrap;
  justify-content: space-between;
  /* justify-content: flex-start; */

}

.language_content .language_block {
  flex-basis: calc((100% / 3) - 4rem);
  border-radius: 5px;
  background: #f6f6f6;
  padding: 40px;
  text-align: center;
  margin-bottom: 5.5rem;
  /* height: 320px; */
  /* cursor: pointer; */
  /* margin-right: 7%; */
}

.lag_title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lag_title .ctry_icon {
  width: 35px;
  margin-right: 12px;
}

.language_content .language_block.lang_right {
  margin-right: 0px;
}

.language_block.active {
  background-color: #a2bb0c;
}

.language_block .lag_title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-bottom: 1px solid #a0a0a0;
  padding-bottom: 20px;
}

.lag_title .ctry_text {
  font-size: 24px;
  /* letter-spacing: -0.1px; */

}

.language_block.active .lag-sml {
  font-size: 18px;
  color: #fff;
}

.language_block .lag_title {
  font-weight: bold;
  color: #000;
}

.language_block .lag_sml {
  color: #000;
  font-size: 24px;
  padding-top: 30px;
}

.language_block .lag_sml a:hover {
  color: #007F8F;
}

.language_block .lag_sml a {
  color: #000;
  display: block;
}

/* 0504 css */


.title_text {
  max-width: 100%;
}

.global_text {

  font-size: 60px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-bottom: 34px;
  margin-top: 0px;
}

.common_btn_border {
  text-transform: uppercase;
}

.language_content {
  max-width: 1280px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6rem;
}

.language_content .language_block {
  height: initial;
}

/* 0628 */
.language_global {
  /* max-width: 1280px; */
  width: 100%;
  margin: 0 auto;

}

.language_global .global_box {
  background: initial;
  border: 1px solid #ddd;
  display: flex;
  padding: 40px;
  align-items: center;
  border-radius: 8px;
  margin: 0px 20px;
}

.global_title {
  display: flex;
}

.global_title_box {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.global_title_img {
  width: 34px;
}

.text_content_wrap {
  margin-top: 20px;
  font-size: 18px;
  line-height: 30px;
  color: #636362;
}

.cont_txt{
  margin: 20px 0 5px;
}

.left_language_text {
  margin-right: 20px;
  max-width: 636px;
  width: 100%;
}

.right_images img {
  max-width: 678px;
}


.global_btn {
  line-height: initial;
}

.content_txt {
  margin: 20px 0px 100px 0px;
}

.language_global {
  max-width: 1380px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.language_block_div {
  padding: 0px 30px !important; 
}

.row_left{
  margin-left: 60px;
}
@media (max-width: 1480px) {
  .language_content {
    width: 100%;
    column-gap: 5rem;
  }

  .global_text {
    font-size: 40px;
  }

  .content_txt {
    margin: 0px 35px 80px 0px;
  }

  .language_content .language_block {
    margin-bottom: 5rem;
  }
  .row_left{
    margin-left: 0px;
  }
  .language_block_div {
    padding: 0px 15px !important; 
  }

  .language__block {
    margin-bottom: 30px;
  }

  .heading__area h1 {
    margin-left: 10px;
  }

  .language_global .global_box {
    margin: 0px 8px;
  }

}

@media (max-width: 1280px) {

  /* 0504 */
  .language_content {
    width: 100%;
    margin: 0px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
  }

  .language_content .language_block {
    flex-basis: calc((100% / 2) - 2rem);
    margin-bottom: 4rem;
  }


  /* 0628 */
  .right_images img {
    max-width: 678px;
    width: 100%;
  }

}

@media (max-width: 768px) {
  .language_content .language_block {
    padding: 20px 20px;
  }

  .title_text,
  .about_title {
    width: 100% !important;
  }

  /* 0628 */
  .language_global .global_box {
    flex-direction: column-reverse;
    align-items: initial;
    padding: 25px;
  }

  .left_language_text {
    margin-right: 0px;
    margin-top: 20px;

  }

  .cont_txt {
    margin-bottom: initial;
  }

  .global_btn {
    margin-bottom: initial;
  }
  
  .language_block_div {
    padding: 0px 10px;
  }

}

@media (max-width: 640px) {
  .language_content {
    grid-template-columns: repeat(1, 1fr);

  }
}