
.steps .svg_arrow .line,
.steps .svg_arrow .arrow {
    stroke: #fff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.steps  .svg_arrow .line {
    stroke-dasharray: 1040;
    stroke-dashoffset: 1040;
    transition: none;
}

.steps .arrow2 .path2 {
    stroke-dasharray:1780;
    stroke-dashoffset:1780;
    transition: none;
}

.steps .arrow3 .path2-03 {
    stroke-dashoffset: 1780;
    stroke-dasharray: 1780;

}

.steps  .svg_arrow .arrow,
.steps  .svg_arrow .words {
    opacity: 0;
    transition: none;
}
.steps .svg_arrow.visible .path2,
.steps .svg_arrow.visible .line {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1.5s ease;
}

.steps li .svg_arrow.visible .words {
    opacity: 1;
    transition: opacity .8s ease .4s;
}

.steps li .svg_arrow.visible .arrow {
    opacity: 1;
    transition: opacity .5s ease 1.2s;
}

  /* 02 */

 
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

     .path {
          fill: none;             
        }
   
      
            .path1-first,
            .path1-03,
            .path1-01,
            .path1 {         
              opacity:0.24;
              fill:none;
              stroke:#A3BA00;
              stroke-width:2;
              stroke-linecap:round;
              stroke-miterlimit:10;
              stroke-dasharray:8;
              enable-background:new;
              
             }
  
              .path2 {  
              stroke: #A2BB0C; 
              stroke-width: 3px;
              stroke-dasharray:1094;
              stroke-dashoffset:1094;
              animation: animation 2s linear forwards;
          
            }
            .arrow2-first .path2-first {  
              stroke: #A2BB0C; 
              stroke-width: 3px;
              stroke-dasharray:632;
              stroke-dashoffset:632;
              animation: animation2-first 2s linear forwards;
          
            }
            .arrow2 .path2-02 {  
              stroke: #A2BB0C; 
              stroke-width: 3px;
              stroke-dasharray:1780;
              stroke-dashoffset:1780;
              animation: animation2 2s linear forwards;
          
            }
            .arrow3 .path2-03 {  
              stroke: #A2BB0C; 
              stroke-width: 3px;
              stroke-dasharray:1780;
              stroke-dashoffset:1780;
              animation: animation3 2s linear forwards;
          
            }
            .st1{fill:#A3BA00;}
            .st2{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}
            @keyframes animation {
              from {
        
                stroke-dashoffset: 1094;
              }
              to {
            
                stroke-dashoffset: 0;
              }
       
              
            } 
            @keyframes animation2-first {
              from {
        
                stroke-dashoffset: 632;
              }
              to {
            
                stroke-dashoffset: 0;
              }
       
            } 
            @keyframes animation2 {
              from {
        
                stroke-dashoffset: 1780;
              }
              to {
            
                stroke-dashoffset: 0;
              }
       
            } 
            @keyframes animation3 {
              from {
        
                stroke-dashoffset: 1780;
              }
              to {
            
                stroke-dashoffset: 0;
              }
       
            } 
      
    
  
        .fadeInAnim .svg_arrow .circle,
        .fadeInAnim .svg_arrow .circle animateMotion,
        .fadeInAnim .svg_arrow .path2{
          /* opacity: 0; */
          transition: none;
          display: none;
        }
        .fadeInAnim .svg_arrow.visible .circle,
        .fadeInAnim .svg_arrow.visible .circle animateMotion{
          /* opacity: 1;   */
          display: block;
          
        }
     
        .fadeInAnim .svg_arrow.visible .path2
        {
          /* opacity: 1;   */
          /* animation: animation 3s linear forwards; */
          display: block;
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 1.5s ease;
        }


        /* arrow2-first */
        
        .fadeInAnim .svg_arrow .circle,
        .fadeInAnim .svg_arrow .circle animateMotion,
        .fadeInAnim .svg_arrow .path2-first{
          /* opacity: 0; */
          transition: none;
          display: none;
        }
        .fadeInAnim .svg_arrow.visible .circle,
        .fadeInAnim .svg_arrow.visible .circle animateMotion{
          /* opacity: 1;   */
          display: block;
          
        }
     
        .fadeInAnim .svg_arrow.visible .path2-first
        {
          /* opacity: 1;   */
          /* animation: animation 3s linear forwards; */
          display: block;
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 1.5s ease;
        }


        
        /* arrow2 */
        .fadeInAnim .svg_arrow .circle,
        .fadeInAnim .svg_arrow .circle animateMotion,
        .fadeInAnim .svg_arrow .path2-02{
          /* opacity: 0; */
          transition: none;
          display: none;
        }
        .fadeInAnim .svg_arrow.visible .circle,
        .fadeInAnim .svg_arrow.visible .circle animateMotion{
          /* opacity: 1;   */
          display: block;
          
        }
     
        .fadeInAnim .svg_arrow.visible .path2-02
        {
          /* opacity: 1;   */
          /* animation: animation 3s linear forwards; */
          display: block;
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 1.5s ease;
        }

        /* arrow3 */
        .fadeInAnim .svg_arrow .circle,
        .fadeInAnim .svg_arrow .circle animateMotion,
        .fadeInAnim .svg_arrow .path2-03{
          /* opacity: 0; */
          transition: none;
          display: none;
        }
        .fadeInAnim .svg_arrow.visible .circle,
        .fadeInAnim .svg_arrow.visible .circle animateMotion{
          /* opacity: 1;   */
          display: block;
          
        }
     
        .fadeInAnim .svg_arrow.visible .path2-03
        {
          /* opacity: 1;   */
          /* animation: animation 3s linear forwards; */
          display: block;
          stroke-dashoffset: 0;
          transition: stroke-dashoffset 1.5s ease;
        }
        .bg_div {
          width: 100%;
          height: auto;
          padding-left:29%;
      }
      .main-title {
        margin: 10px 0;
    }
    @media (max-width: 1600px) {
      .bg_div {
        padding-left:48%;
    }

    }

    @media (max-width: 1300px) {
      .fadeInAnim .svg_arrow{
        display: none;
      }

    }
  

 
        