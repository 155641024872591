.faq_box_temp {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
}

.faq_wrapper_box {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.faq_font {
  padding-top: 30px;
}

.faq_all_title {
  color: #363635;
  font-family: ManropeBold;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 60px;
  padding: 0 57px 35px;
}

.big_title {
  padding-right: 40px;
}

.faq_box .closeTitle::after {
  right: 0px !important;
}

.faq_box .openTitle::after {
  right: 0px !important;
}

.faq_content p {
  padding: 10px 0px;
  line-height: 30px;
  color: #636362;
}

.faq_title h3 {
  color: #000;
  font-size: 18px;
  font-family: 'ManropeBold';
}

.faq_title .faq_icon {
  cursor: pointer;
}

.faq_content {
  display: none;
  padding-bottom: 15px;
}

.faq_detail .faq_box:first-child {
  border-top: 1px solid #DADAD9;
}

.faq_box {
  border-bottom: 1px solid #DADAD9;
  width: 900px;
  /* padding: 20px 0px; */

}

.faq_content .cont_item {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #636362;
}

.faq_box .faq_title {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  cursor: pointer;
}

.faq_box .openTitle {
  width: 100%;
  position: relative;

}

.faq_box .closeTitle {
  position: relative;

}

.faq_box .openTitle::after {
  content: '';
  position: absolute;
  right: 15px;
  display: block;
  top: 48%;
  margin-top: -13px;
  width: 29px;
  height: 29px;
  background: url('./faq-down.svg') no-repeat left top;
  background-size: cover;
  transition: all 0.5s ease;
}

.faq_box .closeTitle::after {
  content: '';
  position: absolute;
  right: 15px;
  display: block;
  top: 48%;
  margin-top: -13px;
  width: 29px;
  height: 29px;
  background: url('./faq-up.svg') no-repeat left top;
  background-size: cover;
  transition: all 0.5s ease;
}

/* .faq-pading .faq-detail{
  margin-left: 35px;
} */

.faq_pading {
  padding: 0 57px 0;
}

.title_content {
  display: flex;
  align-items: center;
}

.left_img {
  margin-right: 20px;
}

.left_img img {
  max-width: 150px;
  /* width: 100%; */
  height: auto;
}

.right_text {
  padding-right: 20px;
}

.title_content .right_text .big_title {
  color: #000;
  font-size: 18px;
  font-family: 'ManropeBold';
  line-height: 30px;
}

.title_content .right_text P {
  padding: 10px 0px;
  line-height: 30px;
  color: #636362;
  font-size: 18px;
  font-weight: normal;
}

.cont_txt {
  margin: 20px 0px 0px 0px;
}

.temp_white_block {
  padding: 0 25px;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}

.hidden {
  display: none;
}

.display {
  display: block;
}

@media (max-width: 1280px) {
  .faq_box {
    max-width: 900px;
    width: 100%;
  }

  .faq_box_temp {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  .faq_font {

    padding-top: 20px;
  }
}

@media (max-width:992px) {
  .faq_title h3 {
    display: inline-block;
    padding-right: 25%;
    font-size: 16px;
    line-height: 28px;
  }

}

@media (max-width:768px) {
  .faq_font {

    padding-top: 20px;
  }

  /* .faq-box {
      padding: 10px 0px;
  } */
  .faq_title h3 {
    display: inline-block;
    padding-right: 15%;
  }

  .faq_pading {
    padding-left: 0px;
    padding-right: 0px;
  }

  .faq_pading .faq_detail {
    margin-left: 0px;
  }

  .right_text {
    padding-right: 50px;
  }

  .faq_all_title {
    padding-left: 0px;
  }

}

@media (max-width:680px) {
  .title_content .right_text P {
    line-height: 30px;
    font-size: 18px;
    padding: 5px 0px;



  }

  .title_content .right_text .big_title {
    color: #000;
    font-size: 18px;
    font-family: 'ManropeBold';
    line-height: 30px;
  }

}

@media (max-width:480px) {
  .title_content {
    display: block;
  }

  .left_img img {
    max-width: 250px;
  }

  .right_text {

    margin-top: 20px;
  }
}

@media (min-width: 1440px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 0 14px;
  }
}
@media (min-width: 1400px) and (max-width: 1439px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    max-width: 1124px;
    width: 100%;
    padding: 0;
  }
  .faq_pading {
    padding: 0 50px 0;
  }
  .faq_all_title {
    padding: 0 50px 35px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    max-width: 1124px;
    width: 100%;
    padding: 0;
  }
  .faq_pading {
    padding: 0 63px 0;
  }
  .faq_all_title {
    padding: 0 63px 35px;
  }
}

@media (min-width: 930px) and (max-width: 1199px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    max-width: 926px;
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    max-width: 692px;
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 539px) {
  .faq_wrapper_box,
  .faq-box-temp {
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }
}