.margin_space{
    margin-bottom: -1%;
    margin-top: 2.5%;
}

@media screen and (max-width: 768px) {
    .margin_space{
        margin-bottom: 4%;
        margin-top: 2.5%;
    }
  }