/* 取消样式规则 */
/* .plyr__video-embed {
  aspect-ratio: unset !important;
} */



.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #A2BB0C;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #A2BB0C)));
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #A2BB0C;
  background: var(--plyr-video-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#A2BB0C)));
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: var(--plyr-video-control-color,#fff);
  display: none;
  left: 50%;
  opacity: .9;
  padding: 15px;
  padding: calc(var(--plyr-control-spacing, 10px)*1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  transition: .3s;
  z-index: 2;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  background: #0000;
  border: 0;
  border-radius: 26px;
  border-radius: calc(var(--plyr-range-thumb-height, 13px)*2);
  color: #A2BB0C;
  color: var(--plyr-range-fill-background,var(--plyr-color-main,var(--plyr-color-main,#A2BB0C)));
  display: block;
  height: 19px;
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px)*2 + var(--plyr-range-thumb-height, 13px));
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow .3s ease;
  width: 100%;
}

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -1.5px;
  margin-left: calc(var(--plyr-range-thumb-height, 13px)*-.5);
  margin-right: -6.5px;
  margin-right: calc(var(--plyr-range-thumb-height, 13px)*-.5);
  width: calc(100%);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}