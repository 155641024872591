.card__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
.individual__try:nth-child(even){
  margin-top: 30px;
}
.card__image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.card__image img {
  max-width: 100%;
  border-radius: 8px;
  height: auto;
}
.card__image:before {
  content: "";
  z-index: 0;
  width: 80%;
  height: 50%;
  opacity: 0;
  background-color: transparent;
  transition: opacity 0.3s cubic-bezier(0.38, 0.41, 0.27, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0.8rem 3.2rem rgb(0 0 0 / 50%);
}
.card__image:after {
  content: "";
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: linear-gradient(
      transparent 0%,
      rgba(0, 0, 0, 0.2) 15%,
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0.2) 75%,
      #000
    )
    0 0 / auto 150%;
  transition: background-position 0.3s cubic-bezier(0.38, 0.41, 0.27, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}
.single__card__button {
  z-index: 1;
  height: 100%;
  flex-flow: column;
  display: flex;
  position: absolute;
  top: 0;
}
.single__card__button button {
  margin: 20px;
}
.singleCard__content {
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0px 20px;
  color: #fff;
  z-index: 1;
}
.singleCard__content h4 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "ManropeBold";
  line-height: 40px;
}

.singleCard__content p {
  line-height: 28px;
  overflow: hidden;
}

.tri__card__container{
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f6f6f6;
}
.zigzagCard__area {
  /* padding: 80px 0; */
  background-color: #f6f6f6;
}
.zigzagCard__container h3 {
  font-size: 88px;
  font-weight: bold;
  color: #363635;
  padding-bottom: 30px;
  text-align: center;
  line-height: 98px;
  font-family: "ManropeBold";
}
.single__zigzag__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 30px 0;
}
.single__triCard__image {
  position: relative;
}
.single__triCard__image img {
  width: 100%;
  border-radius: 8px;
}
.single__triCard__top {
  position: absolute;
  top: 0;
  margin: 20px;
  z-index: 9999;
}
.single__triCard__contents {
  z-index: 1;
  height: 100%;
  width: 100%;
  flex-flow: column;
  display: flex;
  position: absolute;
  bottom: 15px;
  align-items: center;
}
.single__triCard__contents h4 {
  max-width: 100%;
  font-weight: bold;
  line-height: 30px;
  color: #fff;
  font-size: 1.87rem;
  font-family: "ManropeBold";
}
.single__triCard__title {
  position: absolute;
  bottom: 0;
  margin: 32px;
}
/* .card__container:nth-of-type(even) {
  margin-top: 30px;
} */
/* ==== MEDIA QUERIES (SMALL DEVICES) ==== */

@media only screen and (max-width: 1024px) and (min-width: 767px) {
  .card__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .cardWithTitleImageDescButton__container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .zigzagCard__container h3 {
    font-size: 48px !important;
    line-height: 52px !important;
    text-align: left;
    padding: 0;
  }
}

@media screen and (max-width: 766px) {
  .card__container {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
  .cardWithTitleImageDescButton__container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .single__zigzag__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .individual__try:nth-child(even){
    margin-top: 0px;
  }
  .zigzagCard__container h3 {
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }
}

/* @media screen and (max-width: 600px) {
  .card__image:nth-of-type(even) {
    margin-top: 0rem;
  }
} */